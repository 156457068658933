
import ThirdPartyFeedForm from '@/components/ThirdPartyFeeds/ThirdPartyFeedForm.vue'
import store from '../../store'
import { thirdPartyFeed } from '../../store/modules/thirdPartyFeed'
import Vue from 'vue'
import Component, { mixins } from 'vue-class-component'
import { ThirdPartyFeedInList } from '../../types/third_party_feed_types'
import { usersMixin } from '@/mixins/usersMixin'
import { baseViewMixin } from '@/mixins/baseViewMixin'
// load the store module dynamically only when needed
if (!store.state.thirdPartyFeed) store.registerModule('thirdPartyFeed', thirdPartyFeed)

@Component({
  components: { ThirdPartyFeedForm }
})
export default class ThirdPartyFeed extends mixins(Vue, usersMixin, baseViewMixin) {
  headers: Object[] = [
    { text: 'ID', value: 'id' },
    { text: '3P partner', value: 'third_party_partner' },
    { text: 'DSP', value: 'dsp' },
    { text: 'Member', value: 'external_id' },
    { text: 'Name', value: 'name' },
    { text: 'Number of sources', value: 'nb_sources', sortable: false },
    { text: 'Description', value: 'description' },
    { text: 'Action', sortable: false }
  ]
  dialogForm: boolean = false
  isEditForm: boolean = false
  feedId: number | null = null

  get items (): ThirdPartyFeedInList[] {
    return this.$store.getters.getFeeds
  }

  get loading (): boolean {
    return this.$store.getters.getLoadingFeeds
  }

  async mounted () {
    this.onMounted()
    await this.callApi()
  }
  openNewForm () {
    this.isEditForm = false
    this.feedId = null
    this.dialogForm = true
  }

  openEditForm (id: number) {
    this.isEditForm = true
    this.feedId = id
    this.dialogForm = true
  }

  async refreshTable () {
    await this.callApi()
  }

  async callApi () {
    await this.$store.dispatch('getApi3pFeeds')
  }
}
